.site-header {
  align-items: center;
  display: flex;
  height: 4.125rem;
  padding: 0 1rem;
  position: relative;
  border-bottom: 1px solid #D9DFE8;

  @include m('has-banner') {
    border-bottom: 0;
  }

  @include e('menu-input') {
    display: none;
    &:checked ~ .site-header__menu {
      transform: translateX(0);

      @media (max-width: 1023px) {
        &:before {
          content: "";
          position: fixed;
          left: -100vw;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, .5);
          width: 100vw;
        }
      }
    }
  }

  @include e('logotype') {
    img {
      height: auto;

      @media (max-width: 1023px) {
        max-width: 70%;
      }
    }
  }

  @include e('menu') {
    background-color: white;
    bottom: 0;
    padding: 1rem;
    position: fixed;
    right: 0;
    transform: translateX(100%);
    top: 0;
    width: 300px;
    z-index: 10;

    @include e('close-mobile') {
      align-items: center;
      cursor: pointer;
      justify-content: center;
      display: flex;
      border: 1px solid rgba(0, 96, 255, .20);
      position: absolute;
      top: 10px;
      right: 1rem;
      width: 40px;
      height: 40px;
      border-radius: 20px;

      i {
        color: #0062FF;
        font-size: 1.5rem;
      }
    }

    @include e('item') {
      @include m('active') {
        color: rgba(0, 19, 51, 0.50);
      }
    }
  }

  @include e('menu-toggle') {
    align-items: center;
    background-color: #0062FF;
    color: white;
    border-radius: 21px;
    display: flex;
    padding: 3px 12px 3px;
    font-weight: 500;
    position: relative;
    margin-left: auto;
    font-size: 1.2rem;
    line-height: 2.5rem;
    &:hover {
      cursor: pointer;
    }

    @include e('bars') {
      height: 1.5rem;
      margin-left: .75rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      span {
        background-color: white;
        border-radius: 2px;
        height: 2px;
        width: 20px;
      }
    }
    /*span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #cdcdcd;
      border-radius: 3px;
      z-index: 1;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }*/
  }

  .site-header__menu {
    .site-header__menu__admin-links {
      .site-header__menu__language {
        align-items: center;
        display: flex;
        padding-right: 1rem;

        a {
          margin-left: .5rem;

          &:after {
            content: "";
            background-color: #D9DFE8;
            width: 1px;
            position: absolute;
            height: 200%;
            top: 50%;
            right: -1vw;
            transform: translateY(-50%);
            pointer-events: none;
          }

          @media (min-width: 1024px) {
            color: black;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    .site-header__menu {
      padding: 1.875rem;
      nav {
        display: flex;
        flex-direction: column;
        
        a {
          color: #0060FF;
          font-size: 1.25rem;
          line-height: 2.625rem;

          &.active:not(.s-active) {
            color: rgba(0,19,51,0.702);
            font-weight: 500;
          }

          &.sub-active {
            color: rgba(0,19,51,0.702);
            font-weight: 500;
          }
        }

        .site-header__menu__nav__item {
          i {
            display: none;
          }

          .site-header__menu__nav__item__subitems {
            display: flex;
            flex-direction: column;
            padding-left: 1rem;

            a {
              font-size: 1.1rem;
            }
          }
        }
      }

      .site-header__menu__admin-links {
        padding: 1.25rem 0 0 0;
        margin: 1.25rem 0 0 0;
        border-top: 1px solid #D9DFE8;
        display: flex;
        flex-direction: column;

        a {
          color: #0060FF;
          font-weight: 600;
          font-size: 1.25rem;
          line-height: 2.625rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    height: 5.75rem;
    padding: 0 1.375rem;

    .site-header__logotype {
      position: absolute;
      left: 1.375rem;
      img {
        width: 189px;
      }
    }

    .site-header__menu-toggle {
      display: none;
    }

    .site-header__menu {
      align-items: center;
      background-color: transparent;
      display: flex;
      position: relative;
      transform: none;
      left: 0;
      right: 0;
      top: 0;
      padding: 0;
      width: 100%;
      justify-content: center;
      pointer-events: none;

      .site-header__menu__close-mobile {
        display: none;
      }

      nav {
        display: flex;
        pointer-events: all;

        .site-header__menu__nav__item {
          margin: 0 1.375rem;
          position: relative;
          z-index: 1;

          i {
            color: #B4B9C1;
            font-size: .75rem;
            margin-left: .5rem;
          }

          .site-header__menu__nav__item__subitems {
            background-color: white;
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 1px 0 rgba(0,0,0,0.08), 0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 1px 0 rgba(0,0,0,0.04), 0 3px 1px 0 rgba(0,0,0,0.04);
            border-radius: 8px;
            position: absolute;
            top: -10px;
            left: -1.375rem;
            min-width: calc(100% + 2.75rem);
            padding: 3rem 1.375rem .5rem;
            z-index: 3;
            opacity: 0;
            pointer-events: none;
            transform: translateY(-20px);
            transition: transform .3s, opacity .3s;

            a {
              font-size: 1rem;
              white-space: nowrap;
              padding: .75rem 0;
              transition: color .3s;

              &:hover {
                color: #1b7fe8;
              }
            }
          }

          &:hover {
            z-index: 2;
            & > a {
              z-index: 4;
              position: relative;
            }
            & > i {
              z-index: 4;
              position: relative;
            }
            .site-header__menu__nav__item__subitems {
              opacity: 1;
              pointer-events: all;
              transform: translateY(0);
            }
          }
        }

        a {
          color: black;
          font-size: 1.25rem;
          text-decoration: none;
          transition: color .3s;

          &:hover:not(.sub-active) {
            color: rgba(0,19,51,0.702);
          }

          &.sub-active {
            color: rgb(27, 127, 232);
          }

          &.active {
            color: rgba(0,19,51,0.702);
            position: relative;

            &:after {
              background-color: rgba(0, 19, 51, 0.702);
              border-radius: 100%;
              content: "";
              height: 4px;
              width: 4px;
              position: absolute;
              top: calc(100% + .25rem);
              left: calc(50% - 2px);
            }
          }
  
          &:first-child {
            margin-left: 0;
          }
  
          &:last-child {
            margin-right: 0;
          }
        }
      }

      

      .site-header__menu__admin-links {
        display: flex;
        pointer-events: all;
        position: absolute;
        right: 0;
        margin: 0;

        a {
          color: #0062FF;
          font-size: 1rem;
          margin: 0 1vw;
          position: relative;
          font-weight: 500;

          &:first-child {
            margin-left: 0;
          }
  
          &:last-child {
            margin-right: 0;
          }

          &:not(:last-child) {
            padding-right: 4px;
            &:after {
              content: "";
              background-color: #D9DFE8;
              width: 1px;
              position: absolute;
              height: 200%;
              top: 50%;
              right: -1vw;
              transform: translateY(-50%);
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}