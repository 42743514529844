.pricing-checkbox {
  display: none;

  $interval: yearly, monthly;
  @for $i from 0 through 1 {
    &#pricing-plan-#{nth($interval, $i+1)}:checked ~ .pricing {
      .pricing-label[for="pricing-plan-#{nth($interval, $i+1)}"] {
        background-color: #FFFFFF;
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.08), 0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 1px 0 rgba(0,0,0,0.04), 0 3px 1px 0 rgba(0,0,0,0.04);
        border-radius: 8px;
        color: #0060FF;
      }

      @if $i == 0 {
        .package-discount {
          visibility: visible;
        }
      }

      .package-price .interval[data-interval="#{nth($interval, $i+1)}"] {
        display: block;
      }
    }
  }

  $currencies: sek, usd, eur;
  @for $i from 0 through 2 {
    &#pricing-currency-#{nth($currencies, $i+1)}:checked ~ .pricing {
      .pricing-label[for="pricing-currency-#{nth($currencies, $i+1)}"] {
        background-color: #FFFFFF;
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.08), 0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 1px 0 rgba(0,0,0,0.04), 0 3px 1px 0 rgba(0,0,0,0.04);
        border-radius: 8px;
        color: #0060FF;
      }

      .package-price[data-currency="#{nth($currencies, $i+1)}"] {
        display: block;
      }

      .package-discount > div[data-currency="#{nth($currencies, $i+1)}"] {
        display: inline-block;
      }
    }
  }

  @for $i from 0 through 2 {
    &#mobile-package-#{$i}:checked ~ .pricing {
      .pricing-label[for="mobile-package-#{$i}"] {
        background-color: #FFFFFF;
        box-shadow: 0 0 1px 0 rgba(0,0,0,0.08), 0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 1px 0 rgba(0,0,0,0.04), 0 3px 1px 0 rgba(0,0,0,0.04);
        border-radius: 8px;
        color: #0060FF;
      }

      .pricing-card[data-package="#{$i}"] {
        display: block;
      }
    }
  }
}

.pricing {
  .pricing-toggles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .pricing-toggles-row {
      display: flex;
      flex-direction: column;

      @media (min-width: 500px) {
        flex-direction: row;
        padding: 16px;

        .pricing-toggle:first-child {
          margin-right: 18px;
        }
      }
    }

    @media (min-width: 900px) {
      border: 1px solid #E0E0E0;
      border-radius: 16px;
    }
  }

  .pricing-toggle {
    align-items: center;
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 12px;
    }

    p {
      font-size: 15px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &.pricing-package {
      background-color: white;
      border-bottom: 1px solid #E0E0E0;
      position: -webkit-sticky;
      position: sticky;
      top: 4.125rem;
      margin-top: 0;
      margin-bottom: 12px;
      padding: 12px 0;
      z-index: 2;
    }

    @media (min-width: 500px) {
      &:not(:first-child) {
        margin-top: 0px;
      }
    }

    @media (min-width: 900px) {
      flex-direction: row;
      margin-bottom: 0;

      &.pricing-package {
        display: none;
      }
    }
  }

  .pricing-labels {
    background-color: #F5F5F5;
    border-radius: 12px;
    display: flex;
    padding: .25rem;
    user-select: none;

    .pricing-label {
      color: #767676;
      font-size: .85rem;
      line-height: 1.25rem;
      font-weight: 500;
      text-align: center;
      padding: .3rem .75rem;

      &:hover {
        cursor: pointer;
      }
    }

    @media (min-width: 768px) {
      margin-left: 12px;
    }
  }

  .package-price {
    display: none;

    .interval {
      display: none;
    }

    &[data-currency=""] {
      display: block;
    }

    .discount {
      font-weight: 700;
      font-size: 15px;
      color: #00B25B;
      text-align: center;
      line-height: 20px;
      text-transform: uppercase;
      margin-top: 5px;
    }
  }

  .package-discount {
    height: 1.6rem;
    text-align: left;
    visibility: hidden;

    & > div {
      display: none;
      background-color: #06B25B;
      font-size: 1rem;
      line-height: 1rem;
      color: white;
      border-radius: 16px;
      font-weight: 700;
      padding: .4rem .75rem;
      text-transform: uppercase;
      text-align: center;

      @media (min-width: 900px) {
        font-size: .8rem;
        line-height: .8rem;
      }
    }
  }

  .package-recommended {
    background-color: #00B25B;
    border-radius: 15px;
    font-weight: 700;
    font-size: 13px;
    color: #FFFFFF;
    line-height: 18px;
    position: absolute;
    padding: 3px 12px;
    text-transform: uppercase;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .package-price {
    em {
      display: block;
      font-size: 12px;
      color: #767676;
      text-align: center;
      line-height: 16px;
    }

    p {
      font-size: 28px;
      color: #000000;
      text-align: center;
      line-height: 28px;
      font-weight: 200;

      span {
        font-size: 16px;
        color: #767676;
        line-height: 28px;
      }
    }
  }

  .pricing-header {
    .package-name {
      align-items: flex-start;
      display: flex;
    }

    h2 {
      font-size: 2rem;
      color: #000000;
      line-height: 48px;
    }
  }

  .description {
    color: #767676;
    font-size: 16px;
    line-height: 28px;
  }
}

.pricing-cards {
  display: block;
  margin-top: 60px;

  .pricing-header {
    position: relative;

    .package-name {
      align-items: center;
      justify-content: center;
      display: flex;

      h2 {
        line-height: unset;
      }

      .package-price {
        margin-left: auto;
      }
    }
  }

  .package-price {
    margin: 20px 0;
  }

  .included-in-all {
    padding-bottom: 22px;
    padding-top: 22px;
    margin-bottom: 15px;

    li {
      margin-top: 11px;
      &:not(:last-child) {
        margin-bottom: 11px;
      }
    }
  }

  .in-short {
    margin: 15px 0;
  }

  .description {
    color: #767676;
    font-size: 16px;
    line-height: 28px;
    margin-top: 12px;
  }

  .features {
    h3 {
      margin: 6px 0 3px 0;
    }
    p {
      margin: 3px 0 6px 0;
    }
  }
  
  .pricing-card {
    display: none;
    margin: 0 auto;

    & > * {
      padding-right: 32px;
      padding-left: 32px;
    }
    
    @media (min-width: 500px) {
      max-width: 500px;
    }
  }

  @media (min-width: 900px) {
    display: none;
  }
}

.in-short {
  margin: 20px 0;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;

    li {
      align-items: center;
      display: flex;
      font-size: 16px;
      color: #323232;
      line-height: 32px;
      text-align: left;

      &:before {
        color: #00D25C;
        font-family: 'streamify-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-display: optional;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e94b";
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
}

.included-in-all {
  background: #FFFCF5;
  border-top: 1px solid #EAE6DE;
  border-bottom: 1px solid #EAE6DE;
  box-shadow: 2px 2px 0 0 rgba(0,0,0,0.03);
  padding: 12px 16px;
  
  strong {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      font-weight: 600;
      font-size: 14px;
      color: #323232;
      line-height: 20px;
      position: relative;
      display: flex;
      align-items: center;

      &:before {
        color: #00D25C;
        font-family: 'streamify-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-display: optional;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e94b";
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }

  @media (min-width: 500px) {
    border: 1px solid #EAE6DE;
  }
}

.features {
  i {
    font-size: 22px;
  }

  .sficon-checkbox-thin {
    color: #00D25C;
  }
  
  .sficon-close-x {
    color: #DD4036;
  }

  label, h3 {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    line-height: 20px;
  }

  p {
    color: #323232;
    font-size: 14px;
    line-height: 20px;
  }
}

.pricing-table {
  display: none;
  max-width: 1560px;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;

  .package {
    td {
      border-bottom: 0;
    }

    td:first-child {
      padding-top: 0;
    }

    td:not(:first-child) {
      padding-bottom: 0;
    }
  }

  tr.action-buttons {
    td:not(:first-child) {
      border-top: 0;
      padding-top: 0;
      padding-bottom: 50px;
    }
  }

  .included-in-all {
    div {
      align-items: center;
      display: flex;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex: 1;
      padding: 0 55px;

      li {
        &:not(:last-child) {
          padding-right: 15px;
        }
      }
    }
  }

  .description {
    margin-top: 12px;
  }

  .pricing-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  th, td {
    vertical-align: top;
  }

  tr.in-short {
    td {
      &:first-child {
        vertical-align: top;
      }

      border-top: 1px solid #E0E0E0;
      border-bottom: 0;
    }
  }

  tr.included-in-all {
    & + tr {
      td {
        padding-top: 36px !important;
      }
    }
  }

  tr.features {
    td {
      vertical-align: middle;
      &:not(:first-child) {
        text-align: center;
      }
    }
  }

  td {
    border-bottom: 1px solid #E0E0E0;
  }

  tr:last-child {
    td {
      border-bottom: 0;
    }
  }


  td, th {
    // width: calc(33% - 180px);

    &:first-child {
      width: 180px;
      padding: 22px 0 22px 24px;

      label {
        font-weight: 600;
        font-size: 14px;
        color: #000000;
        line-height: 24px;
        word-break: normal;
        display: flex;
        align-items: center;

        &.package-label {
          display: block;
          padding-top: 2.25rem;
        }
      }
    }

    &th:first-child {
      padding: 22px 0 22px 24px;
    }

    &:nth-child(2) {
      padding: 22px 24px 22px 0;
      // width: calc(33% - 144px);
    }

    &:nth-child(3) {
      border-right: 1px solid #E0E0E0;
      border-left: 1px solid #E0E0E0;
    }

    &:not(:first-child):not(:nth-child(2)) {
      padding: 22px 24px 22px;
    }
  }

  th:not(:first-child) {
    padding-top: 70px !important;
    padding-bottom: 10px !important;
  }

  @media (min-width: 900px) {
    display: table;
  }

  h2 {
    font-weight: 600;
    font-size: 34px;
    color: #000000;
    line-height: 48px;
  }
}