.news-article {
    @include e('content') {
        padding: 3.75rem 2rem;

        p, ul {
            font-size: 1.25rem;
            line-height: 2.25rem;
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        img {
            margin: 2rem auto;
            max-width: 90%;
        }

        @include e('date') {
            font-size: 1.25rem;
            line-height: 2.25rem;
            text-transform: uppercase;
        }

        @include e('title') {
            font-size: 2.5rem;
            line-height: 3.125rem;
            font-weight: 500;
            margin-bottom: 1.25rem;
        }

        @include e('lead') {
            font-size: 1.5rem;
            color: #767676;
            line-height: 2.5rem;
            margin-bottom: 1.875rem;

            p {
                font-size: inherit;
                line-height: inherit;
            }
        }

        blockquote {
            background-color: #FAFAFA;
            border-radius: 16px;
            padding: 1.125rem 1.875rem;
            position: relative;
            margin-bottom: 1.5rem;
            margin-top: 3.75rem;

            &:after {
                top: -.75rem;
                left: -0.625rem;
                position: absolute;
                align-items: center;
                justify-content: center;
                content: "\e944";
                display: inline-flex;
                font-family: 'streamify-icons' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                font-size: 1.75rem;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }

    @media (min-width: 1024px) {
        .news-article__content {
            display: flex;
            padding-left: 0;
            padding-right: 0;

            blockquote {
                padding: 2.125rem 3.875rem;

                &:after {
                    top: 2.5rem;
                    left: -0.625rem;
                }
            }
        }

        .news-article__content__title {
            font-size: 4.5rem;
            line-height: 5.125rem;
        }

        .news-article__content__main {
            padding: 0 3.75rem;
        }

        .news-article__content__sidebar {
            min-width: 27%;
            max-width: 27%;
        }
    }
}
