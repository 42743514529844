.site-footer {
    background-color: #1E6AE9;
    background-image: url('/assets/images/footer-background.svg');
    background-size: cover;
    padding: 5rem 2rem;

    h3 {
        color: white;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 2px;
        word-break: break-word;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0.7rem 0;

        li {
            color: white;
            font-weight: 500;
            font-size: 1.0625rem;
            line-height: 2rem;
            margin-bottom: .75rem;

            &.has-icon {
                margin-bottom: .5rem;
            }

            a {
                align-items: center;
                color: #C2FFF7;
                display: inline-flex;
                font-weight: 500;

                i {
                    color: #B4D7FF;
                    font-size: 2rem;
                }
            }
        }
    }

    @include e('social') {
        align-items: center;
        display: flex;
        margin: 1rem 0 0;

        li.site-footer__social {
            line-height: 1;
            margin-right: 1.25rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @include e('logotype') {
        height: auto;
        max-width: 231px;
        margin-bottom: .5rem;
    }

    @include e('inner') {

        @include e('left') {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2rem;

            p {
                color: white;
                font-size: 1rem;
                line-height: 1.5rem;
                width: 100%;
            }

            ul {
                width: 100%;
            }
        }

        @include e('right') {
            display: flex;
            flex-wrap: wrap;

            & > * {
                margin-bottom: 1rem;
                padding: 0 1rem;
                width: 50%;

                &:nth-child(odd) {
                    padding-left: 0;
                }

                &:nth-child(event) {
                    padding-right: 0;
                }
            }
        }
    }

    @include e('bottom') {
        ul {
            list-style: none;
            display: flex;
            justify-content: flex-end;
            li {
                font-size: 1rem;
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @media (min-width: 550px) {
        .site-footer__inner__left {
            p {
                flex: 1;
                margin-left: 1rem;
                width: auto;
            }
        }
    }

    @media (min-width: 768px) {
        ul {
            li {
                margin-bottom: 0;
            }
        }

        .site-footer__inner {
            display: flex;
        }

        .site-footer__inner__left {
            flex: 1;
            flex-direction: column;
            margin-right: 3rem;

            p {
                flex: auto;
                margin-left: 0;
            }
        }

        .site-footer__inner__right {
            flex: 2;
        }
    }

    @media (min-width: 1024px) {
        .site-footer__inner__left {}
        .site-footer__inner__right {
            flex: 4;

            & > * {
                width: 20%;

                &:nth-child(odd), &:nth-child(even) {
                    padding: 0 1rem;
                }
            }
        }
    }
}