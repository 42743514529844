.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 5px;

  span {
    align-items: center;
    justify-content: center;
    display: inline-flex;
    width: 24px;
    height: 24px;
    font-size: 18px;
    background-color: gray;
    color: white;
    border-radius: 12px;
  }
  
  .tooltip-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 300px;
    max-width: 300px;
    padding: 15px;
    text-transform: none;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 45px;
    white-space: normal;
    opacity: 0;
    transition: opacity .4s;
    pointer-events: none;


    background: #FFFFFF;
    border: 1px solid #BEBEBE;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.08), 0 1px 1px 0 rgba(0,0,0,0.06), 0 2px 1px 0 rgba(0,0,0,0.04), 0 3px 1px 0 rgba(0,0,0,0.04);
    font-size: 13px;
    color: #767676;
    line-height: 22px;
    
    &:not(.down) {
      &:after, &:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #FFFFFF;
        border-width: 15px;
        margin-top: -15px;
      }
      &:before {
        border-color: rgba(190, 190, 190, 0);
        border-right-color: #BEBEBE;
        border-width: 16px;
        margin-top: -16px;
      }
    }

    &.down {
      left: -45px;
      top: unset;
      bottom: 45px;
      transform: translateY(0);

      &:after, &:before {
        top: 100%;
        left: 56px;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #FFFFFF;
        border-width: 15px;
        margin-left: -15px;
      }
      &:before {
        border-color: rgba(190, 190, 190, 0);
        border-top-color: #bebebe;
        border-width: 16px;
        margin-left: -16px;
      }
    }
  }
}
.tooltip {
  &:hover, &:focus {
    .tooltip-text {
      // visibility: visible;
      opacity: 1;
    }
  }
}