.banner {
    display: flex;
    // flex-direction: column;
    margin: 0 auto;
    height: 60vw;
    max-width: $max-width;
    position: relative;
    overflow: hidden;
    width: 100%;

    @include e('slides') {
        display: flex;
        height: 100%;
        width: 100%;
        transition: margin .5s;
    }

    @include e('slide') {
        position: relative;
        min-width: 100%;
        height: 100%;

        @include e('inner') {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: absolute;
            height: 100%;
            width: 100%;
            padding: 3rem 2rem;
            top: 0;
            left: 0;
            pointer-events: none;
            @media (min-width: $tablet) {
                // padding: 2.5rem 4rem;
                padding: 4rem;
                max-width: 90%;
            }
            @media (min-width: $desktop) {
                max-width: 80%;
                // padding: 0 7.25rem 4.125rem;
                padding: 4rem 7rem;
            }
    
            h1 {
                color: white;
                font-weight: 700;
                margin-top: auto;
                font-size: 2.75rem;
                line-height: 3.125rem;
        
                @media (min-width: $tablet) {
                    font-size: 3.75rem;
                    line-height: 4.125rem;
                    margin-bottom: 0.75rem;
                }
            }
        
            p {
                color: rgba(white, .8);
                font-size: 1.25rem;
                line-height: 2.125rem;
                @media (min-width: $tablet) {
                    max-width: 80%;
                }
            }
        }

        @include m('gradient') {
            picture {
                &:after {
                    content: "";
                    opacity: 0.5;
                    background-image: linear-gradient(184deg, rgba(0,0,0,0.00) 53%, rgba(0,0,0,0.60) 100%);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                }
            }
        }
    }

    @include e('slideshow-toggles') {
        display: flex;
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translateX(-50%);

        @include e('toggle') {
            background-color: transparent;
            border: 2px solid rgba(255, 255, 255, .60);
            border-radius: 0.375rem;
            height: .75rem;
            margin-right: 1rem;
            width: 2rem;
            transition: border .3s, background-color .3s;

            &:last-child {
                margin-right: 0;
            }

            &:hover:not(.banner__slideshow-toggles__toggle--active) {
                cursor: pointer;
            }

            @include m('active') {
                border: 2px solid white;
                background-color: white;
            }
        }
    }

    picture {
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1;
        width: 100%;
        height: 100%;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &.banner--gradient {
        picture {
            &:after {
                content: "";
                opacity: 0.5;
                background-image: linear-gradient(184deg, rgba(0,0,0,0.00) 53%, rgba(0,0,0,0.60) 100%);
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
            }
        }
    }

    @include m('small') {
        min-height: 300px;
        max-height: 460px;
    }
    @include m('medium') {
        min-height: 350px;
        max-height: 640px;
    }
    @include m('large') {
        min-height: 400px;
        max-height: 820px;
    }

    @include m('video') {
        height: auto;
    }

    @include m('text-under') {
    }

    @include e('video') {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (min-width: $desktop) {
        overflow: hidden;
        border-radius: 32px;

        picture {
            img {
                //border-radius: 32px;
            }
            &:after {
                // border-radius: 32px;
            }
        }

        video {
            // border-radius: 32px;
        }
    }
}