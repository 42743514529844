.news-items {
    padding: 0 20px;

    h1 {
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 5.125rem;
        padding-left: 1.25rem;
        margin: 3.875rem 0;
    }

    @include e('item') {
        margin-bottom: .5rem;

        a {
            display: flex;
            flex-direction: column;
            position: relative;
        }
        @include e('image') {
            position: relative;

            img {
                border-radius: 16px;
                height: auto;
                width: 100%;
            }

            @include e('has-video') {
                align-items: center;
                border: 8px solid rgba(white, .3);
                border-radius: 100%;
                display: flex;
                height: 88px;
                justify-content: center;
                position: absolute;
                width: 88px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
      
                i {
                  color: white;
                  font-size: 4rem;
                  position: relative;
                }
      
                &:before {
                  background-color: black;
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 72px;
                  height: 72px;
                  border-radius: 100%;
                }
              }
        }

        @include e('content') {
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: .5rem;

            h3 {
                color: black;
                font-weight: 600;
                font-size: 1.625rem;
                line-height: 2.25rem;
            }

            p {
                color: #767676;
                font-size: 1.0625rem;
                line-height: 1.6875rem;
                margin-bottom: 0.75rem;
      
                &.news-items__item__content__date {
                  text-transform: uppercase;
                  color: black;
                  margin-bottom: .5rem;
                }
              }
        }

        @include e('arrow') {
            align-items: center;
            justify-content: flex-end;
            display: none;
            width: 200px;
            margin-left: auto;
            padding: 0 2rem;

            i {
                font-size: 1.5rem;
            }
        }
    }

    @media (min-width: 768px) {
        .news-items__item {
            margin-bottom: 2.5rem;

            a {
                flex-direction: row;
            }

            .news-items__item__image {
                min-width: 300px;
                max-width: 300px;
            }

            .news-items__item__content {
                padding: 0 0 0 2.5rem;
            }

            .news-items__item__arrow {
                display: flex;
            }
        }

    }

    @media (min-width: 1024px) {
        padding: 0;
    }
}