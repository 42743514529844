.telegram-section {
  padding: 20px 0;

  h2 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  @include e('header') {
    align-items: center;
    display: flex;
    padding: 0 1.75rem;

    a {
      margin-left: auto;
      font-weight: 500;
      font-size: 1.0625rem;
    }

    @media (min-width: 768px) {
      padding: 0 0.75rem;
    }
  }

  @include e('items') {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    @include e('item') {
      flex: 1;
      margin: 10px 0;

      @include e('image') {
        position: relative;

        img {
          border-radius:  16px;
          height: auto;
          width: 100%;
        }

        @include e('has-video') {
          align-items: center;
          border: 8px solid rgba(white, .3);
          border-radius: 100%;
          display: flex;
          height: 88px;
          justify-content: center;
          position: absolute;
          width: 88px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          i {
            color: white;
            font-size: 4rem;
            position: relative;
          }

          &:before {
            background-color: black;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 72px;
            height: 72px;
            border-radius: 100%;
          }
        }
      }
  
      @include e('content') {
        padding: 1.625rem 1.5rem;
  
        h3 {
          font-size: 1.625rem;
          font-weight: 600;
          line-height: 2.25rem;
          margin-bottom: .25rem;
          color: black;
        }

        p {
          color: #767676;
          font-size: 1.0625rem;
          line-height: 1.6875rem;
          margin-bottom: 0.75rem;

          &.telegram-section__items__item__content__date {
            text-transform: uppercase;
            color: black;
          }
        }

        a {
          display: inline-block;
          font-size: 1.0625rem;
          line-height: 1.6875rem;
        }
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 10px 20px;

    h2 {
      font-size: 1.875rem;
      line-height: 3.25rem;
      margin-bottom: .5rem;
    }

    .telegram-section__items {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
    }

    .telegram-section__items__item {
      margin: 0 2rem 2rem;
      max-width: calc(33%);
      min-width: calc(33% - 4rem);

      .telegram-section__items__item__content {
        // padding: 15px 30px;
      }

      /*&:first-child {
        margin-left: 0;
      }
  
      &:last-child {
        margin-right: 0;
      }*/
      &:nth-child(3n+1) {
        margin-left: 0;
        /*max-width: calc(33% - 2rem);
        min-width: calc(33% - 2rem);*/
      }

      &:nth-child(3n) {
        margin-right: 0;
        /*max-width: calc(33% - 2rem);
        min-width: calc(33% - 2rem);*/
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 1.25rem 0 0;
  }
}