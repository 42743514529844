@font-face {
    font-family: 'streamify-icons';
    src:
      url('/assets/fonts/streamify-icons.ttf?sfddgg') format('truetype'),
      url('/assets/fonts/streamify-icons.woff?sfddgg') format('woff'),
      url('/assets/fonts/streamify-icons.svg?sfddgg#streamify-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'streamify-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-display: optional;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .sficon-chevron-right:before {
    content: "\e97d";
  }
  .sficon-chevron-left:before {
    content: "\e97c";
  }
  .sficon-gift-24:before {
    content: "\e97b";
  }
  .sficon-accounting-24:before {
    content: "\e976";
  }
  .sficon-arrows-flowright-24:before {
    content: "\e977";
  }
  .sficon-heart-24:before {
    content: "\e978";
  }
  .sficon-report-24:before {
    content: "\e979";
  }
  .sficon-settings-gear-24:before {
    content: "\e97a";
  }
  .sficon-arrow-down-large-24:before {
    content: "\e963";
  }
  .sficon-arrow-down-small-24:before {
    content: "\e964";
  }
  .sficon-child-account-24:before {
    content: "\e965";
  }
  .sficon-clock-fill-24:before {
    content: "\e966";
  }
  .sficon-clock-lines-24:before {
    content: "\e967";
  }
  .sficon-copy-24:before {
    content: "\e968";
  }
  .sficon-dashboard-24:before {
    content: "\e969";
  }
  .sficon-information-24:before {
    content: "\e96a";
  }
  .sficon-parent-account-24:before {
    content: "\e96b";
  }
  .sficon-search-magnify-24:before {
    content: "\e96c";
  }
  .sficon-shopping-bag-24:before {
    content: "\e96d";
  }
  .sficon-trend-up-24:before {
    content: "\e96e";
  }
  .sficon-users-24:before {
    content: "\e96f";
  }
  .sficon-arrow-down-medium-24:before {
    content: "\e970";
  }
  .sficon-arrow-up-medium-24:before {
    content: "\e971";
  }
  .sficon-eye-strike-24:before {
    content: "\e972";
  }
  .sficon-eye-24:before {
    content: "\e973";
  }
  .sficon-thumbs-up-24:before {
    content: "\e974";
  }
  .sficon-stream-20:before {
    content: "\e962";
  }
  .sficon-dots-tight-24:before {
    content: "\e958";
  }
  .sficon-dots-24:before {
    content: "\e959";
  }
  .sficon-brush-24:before {
    content: "\e95a";
  }
  .sficon-chat-24:before {
    content: "\e95b";
  }
  .sficon-code-24:before {
    content: "\e95c";
  }
  .sficon-line-graph-24:before {
    content: "\e95d";
  }
  .sficon-miniplayer-24:before {
    content: "\e95e";
  }
  .sficon-support-24:before {
    content: "\e95f";
  }
  .sficon-trash-24:before {
    content: "\e960";
  }
  .sficon-tshirt-product-24:before {
    content: "\e961";
  }
  .sficon-account-24:before {
    content: "\e954";
  }
  .sficon-dollar-24:before {
    content: "\e955";
  }
  .sficon-shoppingbag-24:before {
    content: "\e956";
  }
  .sficon-stream-24:before {
    content: "\e957";
  }
  .sficon-broadcast-no-bar-crossed:before {
    content: "\e953";
  }
  .sficon-hardhat:before {
    content: "\e950";
  }
  .sficon-arrow-move-down:before {
    content: "\e94c";
  }
  .sficon-arrow-move-up:before {
    content: "\e94d";
  }
  .sficon-stop:before {
    content: "\e94e";
  }
  .sficon-trash1:before {
    content: "\e94f";
  }
  .sficon-sort-none:before {
    content: "\e951";
  }
  .sficon-sort-asc:before {
    content: "\e952";
  }
  .sficon-checkbox-thin:before {
    content: "\e94b";
  }
  .sficon-brush:before {
    content: "\e902";
  }
  .sficon-event-small:before {
    content: "\e905";
  }
  .sficon-gallery1:before {
    content: "\e906";
  }
  .sficon-graph:before {
    content: "\e907";
  }
  .sficon-search1:before {
    content: "\e909";
  }
  .sficon-ticket-large:before {
    content: "\e90d";
  }
  .sficon-help1:before {
    content: "\e90f";
  }
  .sficon-support:before {
    content: "\e910";
  }
  .sficon-dots:before {
    content: "\e912";
  }
  .sficon-clock-fill:before {
    content: "\e914";
  }
  .sficon-clock-lines:before {
    content: "\e915";
  }
  .sficon-products-number:before {
    content: "\e917";
  }
  .sficon-arrow-top-right:before {
    content: "\e918";
  }
  .sficon-eye:before {
    content: "\e91a";
  }
  .sficon-like-thumb:before {
    content: "\e91b";
  }
  .sficon-collapse:before {
    content: "\e91d";
  }
  .sficon-expand:before {
    content: "\e91e";
  }
  .sficon-close-large:before {
    content: "\e934";
  }
  .sficon-close-medium:before {
    content: "\e935";
  }
  .sficon-close-small:before {
    content: "\e938";
  }
  .sficon-shoppingbag:before {
    content: "\e933";
  }
  .sficon-floatingplayer:before {
    content: "\e936";
  }
  .sficon-products:before {
    content: "\e937";
  }
  .sficon-chat2:before {
    content: "\e92c";
  }
  .sficon-account:before {
    content: "\e921";
  }
  .sficon-arrow-back:before {
    content: "\e922";
  }
  .sficon-broadcast:before {
    content: "\e923";
  }
  .sficon-chevronRight:before {
    content: "\e924";
  }
  .sficon-code:before {
    content: "\e925";
  }
  .sficon-event:before {
    content: "\e926";
  }
  .sficon-gallery:before {
    content: "\e927";
  }
  .sficon-help:before {
    content: "\e928";
  }
  .sficon-money:before {
    content: "\e929";
  }
  .sficon-plusCircle:before {
    content: "\e92a";
  }
  .sficon-settings:before {
    content: "\e92d";
  }
  .sficon-stacked:before {
    content: "\e92e";
  }
  .sficon-trash:before {
    content: "\e92f";
  }
  .sficon-tshirt:before {
    content: "\e930";
  }
  .sficon-video:before {
    content: "\e931";
  }
  .sficon-videowww:before {
    content: "\e932";
  }
  .sficon-warning:before {
    content: "\e939";
  }
  .sficon-pin:before {
    content: "\e920";
  }
  .sficon-shopping-bag:before {
    content: "\e91f";
  }
  .sficon-book-meeting:before {
    content: "\e93a";
  }
  .sficon-plus:before {
    content: "\e93b";
  }
  .sficon-liveshopping:before {
    content: "\e91c";
  }
  .sficon-currency:before {
    content: "\e919";
  }
  .sficon-location:before {
    content: "\e93c";
  }
  .sficon-network:before {
    content: "\e93d";
  }
  .sficon-arrow-down:before {
    content: "\e916";
  }
  .sficon-airplay:before {
    content: "\e900";
  }
  .sficon-arrow-right:before {
    content: "\e901";
  }
  .sficon-close-x:before {
    content: "\e903";
  }
  .sficon-edit-pen:before {
    content: "\e904";
  }
  .sficon-edit-pen2:before {
    content: "\e908";
  }
  .sficon-fullscreen:before {
    content: "\e90a";
  }
  .sficon-miniplayer:before {
    content: "\e90b";
  }
  .sficon-minus:before {
    content: "\e90c";
  }
  .sficon-pause:before {
    content: "\e90e";
  }
  .sficon-play:before {
    content: "\e911";
  }
  .sficon-soundwave:before {
    content: "\e913";
  }
  .sficon-volume-0-off:before {
    content: "\e93e";
  }
  .sficon-volume-1-low:before {
    content: "\e93f";
  }
  .sficon-volume-2-medium:before {
    content: "\e940";
  }
  .sficon-volume-3-high:before {
    content: "\e941";
  }
  .sficon-back-arrow:before {
    content: "\e942";
  }
  .sficon-arrow-right-black:before {
    content: "\e943";
  }
  .sficon-quote-black:before {
    content: "\e944";
  }
  .sficon-checklist-black:before {
    content: "\e945";
  }
  .sficon-social-li:before {
    content: "\e946";
  }
  .sficon-social-ig:before {
    content: "\e947";
  }
  .sficon-social-fb:before {
    content: "\e948";
  }
  .sficon-chat:before {
    content: "\e949";
  }
  .sficon-productlist:before {
    content: "\e94a";
  }
  .sficon-list2:before {
    content: "\e9bb";
  }
  .sficon-checkmark:before {
    content: "\ea10";
  }
  .sficon-search:before {
    content: "\e92b";
  }
  .sficon-loading:before {
    content: "\e97e";
  }
  