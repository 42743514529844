html {
  @include fluid-type($mobile, $max-width, $fontsize, $fontsizeMax);
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

  padding-top: 4.125rem;
  // padding-top: 7rem;

  @media (min-width: 1024px) {
    padding-top: 5.75rem;
    // padding-top: 11rem;
  }
}

.fourohfour {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5.75rem);
  justify-content: center;

  h1 {
    font-size: 5rem;
  }

  p {
    font-size: 2rem;
  }
}

a {
  color: #0062FF;
  text-decoration: none;

  &:hover {
    color: darken(#0062FF, 20%);
  }

  &.arrow-right {
    font-size: 1.375rem;
    align-items: center;
    display: inline-flex;
    position: relative;
    font-weight: 500;
    line-height: 1.75rem;

    &:before {
      align-items: center;
      justify-content: center;
      content: "\e901";
      display: inline-flex;
      font-family: 'streamify-icons' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 1.75rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 0.625rem;
    }
  }
}

input, select, textarea {
  background-color: white;
  border-radius: 12px;
  min-height: 3rem;
  border: 2px solid transparent;
  color: black;
  font-size: 1.0625rem;
  line-height: 1.6875rem;
  padding: 0.5rem 1.125rem;
  outline: none;
  width: 100%;

  &[data-invalid] {
    border-color: rgb(180, 7, 7);
  }

  &:focus {
    border-color: #0060FF;
  }

  ::placeholder {
    color: #767676;
    opacity: 1;
  }
  
  :-ms-input-placeholder {
    color: #767676;
  }
  
  ::-ms-input-placeholder {
    color: #767676;
  }
}

textarea {
  resize: none;
}

select {
  background-color: white;
  border-radius: 12px;
  border: 2px solid transparent;
  outline: none;

  &:focus {
    border-color: #0060FF;
  }
}

.loading {
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: loading 1.2s linear infinite;
  }

  &.blue {
    &:after {
      border: 6px solid #0060FF;
      border-color: #0060FF transparent #0060FF transparent;
    }
  }
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sticky-header {
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.05), 0 4px 12px 0 rgba(0,0,0,0.04), 0 1px 4px 0 rgba(0,0,0,0.08);

  .site-header {
    border-bottom: 0;
  }
}

.imageRow {
  padding: 1rem 0;

  @media (min-width: 900px) {
    white-space: nowrap;
    display: table;
  }

  .imageCell {
    display: inline-block;
    padding: 10px;
    align-items: center;
    text-align: center;
    width: 49%;

    img {
      margin: 0 auto;
      max-width: 157px;
      width: 100%;
      max-height: 50px;
    }

    @media (min-width: 900px) {
      display: table-cell;
      padding: 0 10px;
      vertical-align: middle;
      width: auto;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}