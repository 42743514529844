@charset "UTF-8";

@import
  'abstracts/mixins',
  'abstracts/variables';

@import
  'base/reset',
  'base/fonts',
  'base/helpers',
  'base/tooltip',
  'base/base';

@import
  'layout/news-items',
  'layout/news-article',
  'layout/page',
  'layout/header',
  'layout/article',
  'layout/footer';

@import
  'components/animate-number',
  'components/action-section',
  'components/banner',
  'components/button',
  'components/card-grid',
  'components/content-section',
  'components/columns-section',
  'components/form',
  'components/form-section',
  'components/prices',
  'components/spacer',
  'components/text',
  'components/testimonials-section',
  'components/telegrams-section',
  'components/telegrams-image-top',
  'components/telegrams-section',
  'components/blocks/text',
  'components/blocks/intro';

.info-banner {
  background: #0041FF;
  padding: 12px 4px;
  max-width: 100% !important;
}

.info-banner-text {
  font-family: Inter, sans-serif;
  font-size: 13px;
  line-height: 19px;
  color: #FFFFFF;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 20px;
  line-height: 24px;
  }
}

.info-banner-text a {
  color: #FFDF98;
}