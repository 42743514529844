/**
 * Display flex
 * and some align-items, justify-content and flex for it..
 */
.d-f {
  display: flex;

  &.ai-c {
    align-items: center;
  }
  &.ai-fs {
    align-items: flex-start;
  }
  &.ai-fe {
    align-items: flex-end;
  }
  &.jc-c {
    justify-content: center;
  }
  &.jc-fs {
    justify-content: flex-start;
  }
  &.jc-fe {
    justify-content: flex-end;
  }
  &.fd-c {
    flex-direction: column;
  }
  .f-1 {
    flex: 1;
  }
  .f-1-25 {
    flex: 1.25;
  }
  .f-1-5 {
    flex: 1.5;
  }
  .f-2 {
    flex: 2;
  }
}

/**
 * Main content container
 */
.container {
  max-width: $max-width;
  margin: 0 auto;
  // transition: padding .5s;
  width: 100%;

  @include m('sticky') {
    background-color: white;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    max-width: none;
    transition: all .3s;
  }

  @media (min-width: $desktop) {
    padding: 0 20px;

    &.container--sticky {
      padding: 0;
      & > * {
        max-width: calc(#{$max-width} - 40px);
        margin: 0 auto;
      }
    }
  }
}
