.columns-section {
    @include e('title') {
        font-size: 2rem;
        line-height: 3.125rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 2rem;
    }

    @include e('after') {
        text-align: center;
        margin-bottom: 2rem;
    }

    @include e('columns') {
        display: flex;
        flex-direction: column;

        @include e('column') {
            padding: 10px 20px;

            h2 {
                font-size: 1.75rem;
                line-height: 2rem;
                font-weight: 500;
                margin-bottom: .5rem;
            }

            & > *:not(h2) {
                font-size: 1.25rem;
                line-height: 2.25rem;
                margin-bottom: 1.25rem;
            }

            @include m('text-left') {
                text-align: left;
            }

            @include m('text-center') {
                text-align: center;
            }

            @include m('text-right') {
                text-align: right;
            }
        }
    }

    @media (max-width: 1023px) {
        padding: 0 20px;
    }

    @media (min-width: 768px) {
        .columns-section__columns {
            flex-direction: row;
        }

        .columns-section__title {
            margin-bottom: 0;
        }

        .columns-section__columns__column {
            flex: 1;
            padding: 2.75rem 3.5rem 3.25rem;

            h2 {
                margin-bottom: 1rem;
            }
        }

        &.columns-section--borders {
            .columns-section__columns__column {
                &:not(:first-child) {
                    border-left: 1px solid #E0E0E0
                }
            }
        }
    }

    @include m('padding-none') {
        padding: 0;
    }

    @include m('padding-small') {
        padding: 1rem 0;
    }

    @include m('padding-medium') {
        padding: 2.5rem 0;
    }

    @include m('padding-large') {
        padding: 5rem 0;
    }

    @include m('padding-largeTopMediumBottom') {
        padding: 5rem 0 2.5rem;
    }

    @include m('padding-largeTopOnly') {
        padding: 5rem 0 0;
    }

    @include m('padding-mediumTopOnly') {
        padding: 2.5rem 0 0;
    }

    @include m('padding-smallTopOnly') {
        padding: 1rem 0 0;
    }

    @include m('padding-largeBottomOnly') {
        padding: 0 0 5rem;
    }

    @include m('padding-mediumBottomOnly') {
        padding: 0 0 2.5rem;
    }

    @include m('padding-smallBottomOnly') {
        padding: 0 0 1rem;
    }
}