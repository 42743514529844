/// Containers maximum width
/// @type Length
$max-width: 1560px !default;

/// Breakpoints
$mobile: 320px;
$tablet: 768px;
$desktop: 1024px;
$largeDesktop: 1280px;

/// Fontsizes
$fontsize: 12px;
$fontsizeMax: 16px;