.spacer {
    display: flex;
    width: 100%;

    &:after {
        background-color: #E0E0E0;
        content: "";
        height: 1px;
        width: 100%;
    }

    @include m('hide') {
        @media (max-width: 767px) {
            display: none;
        }
    }

    @include m('padding-none') {
        padding: 0;
    }

    @include m('padding-small') {
        padding: 1rem 0;
    }

    @include m('padding-medium') {
        padding: 2.5rem 0;
    }

    @include m('padding-large') {
        padding: 5rem 0;
    }

    @include m('padding-largeTopMediumBottom') {
        padding: 5rem 0 2.5rem;
    }

    @include m('padding-largeTopOnly') {
        padding: 5rem 0 0;
    }

    @include m('padding-mediumTopOnly') {
        padding: 2.5rem 0 0;
    }

    @include m('padding-smallTopOnly') {
        padding: 1rem 0 0;
    }

    @include m('padding-largeBottomOnly') {
        padding: 0 0 5rem;
    }

    @include m('padding-mediumBottomOnly') {
        padding: 0 0 2.5rem;
    }

    @include m('padding-smallBottomOnly') {
        padding: 0 0 1rem;
    }
}