.action-section {
  display: flex;
  flex-direction: column;

  @include e('action') {
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;

    h2 {
      font-size: 1.8rem;
      line-height: 2rem;
      font-weight: 700;
      margin-bottom: auto;
    }

    p {
      color: #767676;
      font-size: 1.0625rem;
      line-height: 1.6875rem;
      margin: .5rem 0;
    }

    @include m('mobile') {
      color: black;
      text-decoration: none;

      &:first-of-type {
        border-top: 1px solid #EBEBEB;
      }
    }
    @include m('desktop') {
      display: none;
    }

    @include e('desktop-button') {
      display: none;
      margin-top: auto;

      .button {
        text-align: center;
      }
    }
    @include e('mobile-button') {
      align-items: center;
      display: flex;
      font-size: 1.5rem;
      justify-content: center;
      padding: 0 5px 0 15px;
      margin-left: auto;

      @include m('red') {
        color: #FA5146;
      }
      @include m('green') {
        color: #009F9B;
      }
      @include m('purple') {
        color: #6F00DE;
      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 0 !important;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 0 15px;

    .action-section__action {
      border-bottom: 0;
      flex-direction: column;

      h2 {
        font-size: 2rem;
        line-height: 3.25rem;
        margin-bottom: 0.125rem;
      }

      p {
        margin: 0 0 1rem;
      }

      .action-section__action__desktop-button {
        display: block;
      }
      .action-section__action__mobile-button {
        display: none;
      }

      &.action-section__action--mobile {
        display: none;
      }
      &.action-section__action--desktop {
        display: flex;
      }

      &:first-child {
        border-top: 0;
      }

      padding: 2.75rem 2.125rem 3.25rem;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 35px;
  }
}