.form-section {
  padding: 3.25rem 2rem;

  @include e('content') {
    order: 0;

    @include e('title') {
      color: #101010;
      font-size: 2.25rem;
      line-height: 3.625rem;
      font-weight: 500;
    }

    @include e('text') {
      color: #5A5A5A;
      font-size: 1.125rem;
      line-height: 2rem;
    }

    @include e('form') {
      margin-top: 0.9375rem;

      input {
        margin-right: 0.75rem;
        margin: 0 0.375rem 1.5rem;
        width: calc(50% - .75rem);
        
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }

      button {
        min-width: 100%;
      }
    }
  }
  @include e('image') {
    order: 1;
    margin-bottom: 1rem;

    img {
      height: auto;
      margin: 0 auto;
      max-width: 70%;
      width: 100%;
    }
  }

  @include m('image-right') {}
  @include m('image-left') {
    .form-section__content {
      order: 1;
    }
    .form-section__image {
      order: 0;
    }
  }

  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    border-radius: 16px;
    padding: 3.25rem 0 3.25rem 2rem;

    .form-section__image img {
      min-width: 400px;
      max-width: 100%;
    }

    .form-section__content__form {
      input {
        width: 45%;
      }

      button {
        min-width: 40%;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 1.875rem 0;

    .form-section__content__form {
      input {
        width: 40%;
      }
    }

    &.form-section--image-right {
      padding-left: 5rem;

      .form-section__content {
        padding-right: 1.1875rem;
      }

      .form-section__image {
        margin-right: -2.875rem;
      }
    }

    &.form-section--image-left {
      padding-right: 5rem;

      .form-section__content {
        padding-left: 1.1875rem;
      }

      .form-section__image {
        margin-left: -2.875rem;
      }
    }

    .form-section__image img {
      min-width: 400px;
    }
  }

  @media (min-width: 1280px) {
    .form-section__image img {
      min-width: 676px;
    }
  }
}