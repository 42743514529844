.telegrams--image-top {
    display: flex;
    // flex-direction: column;
    margin: 0 auto;
    // height: 60vw;
    max-width: $max-width;
    position: relative;
    overflow: hidden;
    width: 100%;

    picture {
        // position: absolute;
        // top: 0;
        // left: 0;
        // z-index: -1;
        // width: 100%;
        // height: 100%;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    // min-height: 350px;
    // max-height: 640px;

    @media (min-width: $desktop) {
        overflow: hidden;
        border-radius: 32px;

        picture {
            img {
                //border-radius: 32px;
            }
            &:after {
                // border-radius: 32px;
            }
        }
    }
}
