.animate-number {
  header {
    align-items: center;
    color: #45698C;
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    // flex-wrap: wrap;

    i {
      font-size: 5rem;
      margin-right: 1.625rem;
    }

    span {
      font-size: 4rem;
      letter-spacing: -0.025px;
      line-height: 4.5rem;
    }

    @media (min-width: 768px) {
      span {
        font-size: 2.5rem;
      }
      i {
        font-size: 3.5rem;
      }
    }

    @media (min-width: 1024px) {
      span {
        font-size: 4rem;
      }
      i {
        font-size: 5rem;
      }
    }
  }

  section {
    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 500;
      text-align: center;
    }

    p {
      font-size: 1.125rem;
      color: #767676;
      text-align: center;
      line-height: 1.75rem;
    }
  }
}