.form {
  @include e('content') {
    @include e('form') {
      &[data-state='error'], &[data-state='invalid'] {
        .form__content__form__error {
          display: block;
        }
      }

      &[data-state='error'] {
        .form__content__form__error__error {
          display: block;
        }
      }

      &[data-state='invalid'] {
        .form__content__form__error__invalid {
          display: block;
        }
      }

      &[data-state='loading'] {
        .form__content__form__inputs {
          display: none;
        }
        .form__content__form__loading {
          display: flex;
        }
      }
      
      &[data-state='sent'] {
        .form__content__form__inputs, .form__content__form__loading {
          display: none;
        }
        .form__content__form__posttext {
          display: block;
        }
      }

      @include e('error') {
        display: none;
        text-align: center;
        & > * {
          display: none;
        }
      }

      @include e('posttext') {
        display: none;
      }

      @include e('loading') {
        display: none;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @include m('sidebar') {
    padding: 2rem;
    border-radius: 1rem;

    h2 {
      color: #101010;
      font-size: 1.5rem;
      line-height: 3rem;
      font-weight: 500;
      margin-bottom: 1rem;
      text-align: center;
    }

    .form__image {
      display: none;
    }

    .form__content__form__inputs {
      text-align: left;
      
      & > * {
        margin: 0 0 1rem;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @include m('page') {
    padding: 3.25rem 2rem;
    margin-top: 2rem;

    .form__content__title {
      color: #101010;
      font-size: 2.25rem;
      line-height: 3.625rem;
      font-weight: 500;
      text-align: center;
    }

    .form__content__form__inputs {
      text-align: center;
    }

    .form__content__text {
      color: #5A5A5A;
      font-size: 1.125rem;
      line-height: 2rem;
      text-align: center;
    }

    .form__image {
      order: 1;
      margin-bottom: 2rem;

      img {
        margin: 0 auto;
        max-width: 70%;
      }
    }

    .form__content__form {
      margin-top: 0.9375rem;

      input {
        margin-right: 0.75rem;
        margin: 0 0.375rem 1.5rem;
        width: calc(50% - .75rem);
        
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }

      button {
        min-width: 100%;
      }
    }

    @media (min-width: 768px) {
      align-items: center;
      border-radius: 16px;
      display: flex;
      padding: 3.25rem 0rem 3.25rem 2rem;

      .form__content__title {
        text-align: center;
      }
  
      .form__content__text {
        text-align: center;
      }

      .form__image {
        img {
          max-width: 100%;
        }
      }

      .form__content {
        padding-right: 1.1875rem;
      }

      .form__content__form {
        input {
          width: 45%;
        }

        textarea {
          width: calc(90% + .9rem);
          margin-bottom: 1.5rem;
        }
  
        button {
          min-width: 45%;
        }
      }
  
      .form__image {
        min-width: 50%;
        margin-bottom: 0;
      }
    }
  
    @media (min-width: 1024px) {
      padding: 1.875rem 0 1.875rem 5rem;

      .form__content__form {
        input {
          width: 40%;
        }

        button {
          min-width: 40%;
        }

        textarea {
          width: calc(80% + .9rem);
        }
      }
    }
  }

  @include m('signup') {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    padding: 1rem 2rem;

    .form__content {
      margin-right: 0;
      width: 100%;
      max-width: 400px;
    }

    .form__content__form {
      .form__content__form__inputs {
        & > * {
          margin: 0 0 1rem;
          width: 100%;
        }

        input, select {
          background-color: rgb(251, 252, 254);
          border: 1px solid rgb(217, 227, 247);

          &:focus {
            border: 1px solid rgb(0, 96, 255);
          }
        }

        select {
          -moz-appearance:none; /* Firefox */
          -webkit-appearance:none; /* Safari and Chrome */
          appearance:none;
        }

        .select-wrapper {
          position: relative;

          &:after {
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
            position: absolute;
            align-items: center;
            justify-content: center;
            content: "\e916";
            display: inline-flex;
            font-family: 'streamify-icons' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: 1rem;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: rgb(0, 96, 255);
          }
        }
      }
    }
  }
}