.block-intro {
  margin-bottom: 2.5rem;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 1.875rem;

    @media (min-width: 768px) {
      font-size: 4.5rem;
    }
  }

  p {
    font-size: 1.75rem;
    color: #474747;
  }
}