.article {
  padding-bottom: 2.5rem;

  .article-image {
    max-width: $max-width;
    overflow: hidden;
    margin: 0 auto 2.5rem;
    width: 100%;

    picture {
      width: 100%;
    }

    @media (min-width: $desktop) {
      picture img {
        border-radius: 32px;
      }
      padding: 0 20px;
    }
  }
  & > * {
    &:first-child:not(.article-image) {
      margin-top: 2.5rem;
    }
  }

  .block-text, .block-intro {
    max-width: 1048px;
    margin: 0 auto 2.5rem;
    padding: 0 20px;

    &:not(.block-intro) {
      margin: 0 auto;
    }
  }
  .form--page {
    border-radius: 0;
    
    .form__content {
      margin: 0 auto;
    }
  }
}

.picture-image {
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}