.text-content {
  @media (min-width: 768px) {
    & > * {
      max-width: 80%;
      margin: 0 auto;
    }
  }

  h1 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 5rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 4rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
    color: #767676;
    line-height: 2.5rem;
  }

  @include m('text-center') {
    text-align: center;
  }

  @include m('text-right') {
    text-align: right;
  }

  @include m('padding-small') {
    padding: 1rem 2.5rem;
  }

  @include m('padding-medium') {
    padding: 2.5rem 2.5rem;
  }

  @include m('padding-large') {
    padding: 5rem 2.5rem;
  }

  @include m('padding-largeTopOnly') {
    padding: 5rem 2.5rem 0;
  }

  @include m('padding-mediumTopOnly') {
    padding: 2.5rem 2.5rem 0;
  }

  @include m('padding-smallTopOnly') {
    padding: 1rem 2.5rem 0;
  }

  @include m('padding-largeBottomOnly') {
    padding: 0 2.5rem 5rem;
  }

  @include m('padding-mediumBottomOnly') {
    padding: 0 2.5rem 2.5rem;
  }

  @include m('padding-smallBottomOnly') {
    padding: 0 2.5rem 1rem;
  }

}