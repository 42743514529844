.page {
    @media (min-width: 1024px) {
        margin-bottom: 4rem;
    }

    .container {
        & > * {
            &:first-child:is(.block-text) {
                padding-top: 2.5rem;
            }
        }
    }
}