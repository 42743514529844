.block-text {
  h1 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 1.25rem;
  }

  h2 {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.75;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.75;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75;
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    line-height: 1.75;
  }

  ul {
    li {
      font-size: 1.25rem;
      line-height: 1.75;
    }
  }

  blockquote {
    background-color: #FAFAFA;
    border-radius: 16px;
    padding: 1.125rem 1.875rem;
    // position: relative;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;

    p {
      margin-bottom: 0;
    }

    p:first-child {
      position: relative;

      &:after {
        top: -2rem;
        left: -3rem;
        position: absolute;
        align-items: center;
        justify-content: center;
        content: "\e944";
        display: inline-flex;
        font-family: 'streamify-icons' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: 1.75rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    @media (min-width: 1024px) {
      padding: 2.125rem 3.875rem;

      p:first-child {
        &:after {
          top: .5rem;
          left: -4.550rem;
        }
      }
    }
  }

  .picture-image {
    &:not(.left):not(.right) {
      img {
        margin: 0 auto 1.5rem;
      }
    }

    &.left {
      @media (max-width: 599px) {
        margin: 0 0 1.5rem;
        img {
          margin: 0 auto;
        }
      }

      @media (min-width: 600px) {
        float: left;
        margin: 0 1.5rem 1.5rem 0;
        max-width: 50%;
      }
    }

    &.right {
      @media (max-width: 599px) {
        margin: 0 0 1.5rem;

        img {
          margin: 0 auto;
        }
      }

      @media (min-width: 600px) {
        float: right;
        margin: 0 0 1.5rem 1.5rem;
        max-width: 50%;
      }
    }

    &.full {
      width: 100%;
    }

    &.half {
      width: 100%;
      @media (min-width: 768px) {
        width: 50%;
      }
    }

    &.third {
      width: 33.33%;
    }

    &.quarter {
      width: 25%;
    }
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  table {
    width: 100%;
    
    @media (max-width: 767px) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }

    th {
      padding: 0 .25rem;
      text-align: left;
    }

    td {
      padding: 0 .25rem;
    }
  }
}

.page {
  .block-text {
    @media (max-width: 1023px) {
      padding: 0 20px;
    }
  }
}

.page.landingpage {
  .block-text {
    @media (max-width: 1023px) {
      padding: 3rem 20px 0;
    }
    @media (min-width: 1024px) {
      padding: 3.375rem 20px 0;
    }

    h3 {
      margin: 5px 0 0;
    }
  }

  .card-grid {
    background-color: unset;

    .card {
      box-shadow: unset;
    }
  }

  .form.form--page .form__content {
    padding: 0;
    margin: 0 auto;
  }
}