.buttons {
  margin: 1rem 0;
  .button {
    margin-bottom: .5rem;
  }
}

.button {
  background-color: black;
  border: 0;
  border-radius: 26px;
  color: white;
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.35rem;
  padding: 1rem 1.5rem;
  position: relative;
  text-decoration: none;
  transition: background-color .25s;

  &:hover {
    cursor: pointer;
  }

  &[data-icon] {
    padding-left: 3.5rem;

    &:before {
      font-family: 'streamify-icons' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-display: optional;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      left: 1.25rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5rem;
    }

    &[data-icon="shopping"] {
      &:before {
        content: "\e96d";
      }
    }
    &[data-icon="play"] {
      &:before {
        content: "\e911";
      }
    }

    &[data-icon="book-meeting"] {
      &:before {
        content: "\e93a";
      }
    }
  }

  @include m('red') {
    background-color: #EE0700;
    color: white;

    &:hover {
      background-color: darken(#EE0700, 10%);
      color: white;
    }
  }

  @include m('blue') {
    background-color: #0060FF;
    color: white;

    &:hover {
      background-color: darken(#0060FF, 10%);
      color: white;
    }
  }

  @include m('blue-border') {
    border: 1px solid #0060FF;
    background-color: transparent;
    color: #0060FF;

    &:hover {
      border: 1px solid darken(#0060FF, 10%);
      color: darken(#0060FF, 10%);
    }
  }

  @include m('green') {
    background-color: #008674;
    color: white;

    &:hover {
      background-color: darken(#008674, 10%);
      color: white;
    }
  }
  @include m('purple') {
    background-color: #6F00DE;
    color: white;

    &:hover {
      background-color: darken(#6F00DE, 10%);
      color: white;
    }
  }

  @include m('transparent') {
    background-color: transparent;
    color: #0060FF;

    &:hover {
      color: darken(#0060FF, 10%);
    }
  }

  @include m('full-width') {
    text-align: center;
    width: 100%;
  }

  @include m('extra-side-padding') {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}