.card-grid {
  background-color: #FAFAFA;
  padding: 2.5rem 1.5rem 0;

  & > h2 {
    color: #101010;
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  @include e('post-text') {
    color: #969696;
    font-size: 1.125rem;
    line-height: 3rem;
    padding: 0.625rem 0 1.125rem;
    text-align: center;
  }

  @include e('cards') {
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: calc(100% - 1rem);
      margin: .5rem;
    }
  }

  @media (min-width: 500px) {
    .card-grid__cards {
      & > * {
        width: calc(50% - 1rem);
      }
    }
  }

  @media (min-width: 1024px) {
    border-radius: 32px;
    padding: 2.5rem 4.75rem 0;
    
    & > h2 {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
      line-height: 1.34;
    }

    .card-grid__cards {
      & > * {
        width: calc(33.333% - 1rem);
      }
    }

  }

  @include m('border-radius') {
    @include m('top') {
      @media (min-width: 1024px) {
        border-radius: 32px 32px 0 0;
      }
    }
    @include m('bottom') {
      @media (min-width: 1024px) {
        border-radius: 0 0 32px 32px;
      }
    }
    @include m('none') {
      @media (min-width: 1024px) {
        border-radius: 0;
      }
    }
  }
}

.card {
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 6px 12px 0 rgba(0,0,0,0.04), 0 2px 4px 0 rgba(0,0,0,0.04);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.625rem;

  @include e('title') {
    color: #101010;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 3rem;
    text-align: center;
  }

  @include e('text') {
    color: #5A5A5A;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    max-width: 90%;
  }
}