.content-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    position: relative;

    @include m('padding-none') {
        padding-top: 0;
        padding-bottom: 0;
    }

    @include m('padding-small') {
        padding-top: .5rem;
        padding-bottom: .5rem;

        @media (min-width: 768px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
      }
    
    @include m('padding-medium') {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        @media (min-width: 768px) {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }
    }
    
    @include m('padding-large') {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        @media (min-width: 768px) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }
    
    @include m('padding-largeTopOnly') {
        padding-top: 2.5rem;
        padding-bottom: 0;

        @media (min-width: 768px) {
            padding-top: 5rem;
            padding-bottom: 0rem;
        }
    }
    
    @include m('padding-mediumTopOnly') {
        padding-top: 1.5rem;
        padding-bottom: 0;

        @media (min-width: 768px) {
            padding-top: 2.5rem;
            padding-bottom: 0rem;
        }
    }
    
    @include m('padding-smallTopOnly') {
        padding-top: .5rem;
        padding-bottom: 0;

        @media (min-width: 768px) {
            padding-top: 1rem;
            padding-bottom: 0;
        }
    }
    
    @include m('padding-largeBottomOnly') {
        padding-top: 0;
        padding-bottom: 2.5rem;

        @media (min-width: 768px) {
            padding-top: 0;
            padding-bottom: 5rem;
        }
    }
    
    @include m('padding-mediumBottomOnly') {
        padding-top: 0;
        padding-bottom: 1.5rem;

        @media (min-width: 768px) {
            padding-top: 0;
            padding-bottom: 2.5rem;
        }
    }
    
    @include m('padding-smallBottomOnly') {
        padding-top: 0;
        padding-bottom: .5rem;

        @media (min-width: 768px) {
            padding-top: 0;
            padding-bottom: 1rem;
        }
    }

    @include m('large-font') {
        .content-section__content {
            h1, h2 {
                font-weight: 700;
                font-size: 2.5rem;
                line-height: 3.5rem;
            }

            @media (min-width: 768px) {
                h1, h2 {
                    font-size: 3.5rem;
                    line-height: 4.5rem;
                }
            }

            @media (min-width: 1024px) {
                h1, h2 {
                    font-size: 5rem;
                    line-height: 6rem;
                }
            }
        }
    }

    & > * {
        position: relative;
    }

    @include e('media') {
        order: 1;
        @include e('video') {
            border-radius: 12px;
            overflow: hidden;
            max-width: 100%;
        }

        @include e('image') {
            img {
                height: auto;
            }
        }
    }

    @include e('content') {
        margin-top: 1.5rem;
        order: 2;
        width: 100%;

        h2 {
            font-size: 3rem;
            line-height: 3.625rem;
            font-weight: 700;
            word-break: break-word;
        }

        p {
            color: #767676;
            font-size: 1.5rem;

            &.small {
                font-size: .95rem;
                line-height: 1.625rem;
            }
        }

        a.arrow-right {
            margin-top: 1.25rem;
        }

        ul {
            list-style: none;
            padding-left: 0;
            li {
                align-items: center;
                display: flex;
                font-size: 1.2rem;
                margin-bottom: 1rem;

                &:before {
                    content: "\e912";
                    color: #00CE69;
                    font-family: 'streamify-icons' !important;
                    font-size: 2rem;
                    speak: never;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    text-align: center;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    margin-right: 0.75rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include m('portraitPhoneVideoRight') {
        &.content-section {
            .content-section__content {
                order: 1;
                padding: 0;
                @media (min-width: 768px) {
                    padding: 0 2rem 0 0;
                }
            }
        }

        .content-section__phone {
            order: 2;
            @media (min-width: 500px) {
                margin-right: -60px;
            }
        }
    }

    @include m('portraitPhoneVideoLeft') {
        &.content-section {
            .content-section__content {
                order: 2;
                padding: 0;
                @media (min-width: 768px) {
                    padding: 0 0 0 2rem;
                }
            }
        }

        .content-section__phone {
            order: 1;
            @media (min-width: 500px) {
                margin-left: -60px;
            }
        }
    }

    &.content-section--portraitPhoneVideoRight, &.content-section--portraitPhoneVideoLeft {
        align-items: center;
        display: block;
        overflow: hidden;

        @media (min-width: 768px) {
            display: flex;
        }

        &.content-section {
            .content-section__content {
                @media (min-width: 1024px) {
                    width: 90%;
                    margin-top: 0;
                }
            }
        }

        @media (min-width: 500px) {
            flex-direction: row;
        }

        p {
            &:not(.small) {
                margin: 1rem 0;
                font-size: 1.25rem;
                color: #767676;
                line-height: 2.5rem;
            }

            @media (min-width: 768px) {
                &:not(.small) {
                    font-size: 1.75rem;
                    line-height: 3rem;
                }
            }
        }
    }

    @include e('phone') {
        position: relative;
        width: 65%;
        margin-right: -23%;
        margin-bottom: -30px;
        float: right;

        @media (min-width: 768px) {
            margin-right: 0;
            float: none;
            margin-bottom: 0;
        }

        @media (min-width: 1024px) {
            margin-bottom: -60px;
        }

        video.content-section__media__video {
            border-radius: 0 0 10px 10px;
            position: absolute;
            width: 64.2%;
            left: 18%;
            top: 8.5%;
            z-index: -1;

            @media (min-width: 1024px) {
                max-width: none;
                // border-radius: 0;
            }
        }
    }

    @include m('imageAsBackgroundVideoRight') {}
    @include m('imageAsBackgroundVideoLeft') {
        overflow: hidden;

        .content-section__content {
            h2 {
                color: white;
            }
            p {
                color: #D5E5F5;
            }
        }

        .content-section__media__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;

            picture {
                display: inline;
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }
    }
    @include m('imageRight') {
        & > * {
            flex: 1;
        }

        .content-section__content {
            order: 1;

            h1 {
                font-size: 3.5rem;
                font-weight: 700;
                line-height: 4rem;
                margin-bottom: 1.2rem;
            }

            h2 {
                margin-bottom: 1.25rem;
            }

            p {
                &:not(.small) {
                    color: #5A5A5A;
                    font-size: 1.25rem;
                    line-height: 2.25rem;
                    margin-bottom: 1.25rem;
                }
            }
        }

        .content-section__content-side {
            order: 2;
            width: 100%;
        }
        
        .content-section__media__image {
            margin-top: 2rem;
            max-width: 100%;
            order: 2;
            width: 100%;

            img {
                margin: 0 auto;
            }

            @media (min-width: 1024px) {
                margin-right: -4rem;
            }
        }
    }
    @include m('imageLeft') {
        & > * {
            flex: 1;
        }

        .content-section__content {
            h1 {
                font-size: 3.5rem;
                font-weight: 700;
                line-height: 4rem;
                margin-bottom: 1.2rem;
            }

            h2 {
                margin-bottom: 1.25rem;
                text-align: center;
            }

            p {
                &:not(.small) {
                    color: #5A5A5A;
                    font-size: 1.25rem;
                    line-height: 2.25rem;
                    margin-bottom: 1.25rem;
                }
            }
        }

        @media (min-width: 1024px) {
            .content-section__content {
                h2 {
                    text-align: left;
                }
            }
        }

        .content-section__media__image {
            max-width: 100%;
            width: 100%;
            img {
                margin: 0 auto;
            }
            @media (min-width: 1024px) {
                margin-left: -4rem;
            }
        }
    }
    @include m('smallImageLeft') {
        & > * {
            flex: 1;
        }

        .content-section__content {
            h1 {
                font-size: 2.5rem;
                line-height: 3rem;
                font-weight: 700;
                margin-bottom: 0.625rem;
            }

            h2 {
                margin-bottom: 1.25rem;
            }

            p {
                &:not(.small) {
                    color: #5A5A5A;
                    font-size: 1.25rem;
                    line-height: 2.25rem;
                    margin-bottom: 1.25rem;
                }
            }
        }

        .content-section__media__image {
            max-width: 300px;
            width: 100%;
            img {
                margin: 0 auto;
            }
        }
    }
    @include m('videoRight') {}
    @include m('videoLeft') {}
    @include m('textCenter') {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        h1 {
            font-size: 1.625rem;
            line-height: 2.125rem;
            font-weight: 700;
            margin-bottom: 0.625rem;
        }

        h2 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 700px;
        }

        p {
            color: #767676;
            font-size: 1rem;
            line-height: 1.625rem;
        }

        .content-section__content {
            text-align: center;
            margin-top: 0;
        }
    }

    @media (min-width: 1024px) {
        border-radius: 32px;
        flex-direction: row;
        padding-left: 5rem;
        padding-right: 5rem;

        .content-section__media__video {
            border-radius: 24px;
            max-width: 40%;
        }

        .content-section__content {
            padding: 0 0 0 5rem;

            h3 {
                // font-size: 3.5rem;
                // line-height: 3.7rem;
            }
        }

        &.content-section--textCenter {
            padding-left: 2.5rem;
            padding-right: 2.5rem;

            h1 {
                font-size: 4.5rem;
                line-height: 1.14;
                font-weight: 700;
            }

            h2 {
                font-size: 3rem;
                line-height: 4rem;
                font-weight: 700px;
            }

            p {
                font-size: 1.525rem;
                line-height: 2.5rem;
            }

            .content-section__content {
                margin: 0 auto;
                padding: 0;
                max-width: 70%;
            }
        }

        &.content-section--imageLeft {
            .content-section__media__image {
                max-width: 50%;
            }

            .content-section__content-side {

            }
        }

        &.content-section--smallImageLeft {
            padding: 3rem 2rem;
            & > * {
                flex: auto;
            }

            h1 {
                font-size: 4.5rem;
                font-weight: 700;
                line-height: 5.125rem;
                margin: 1rem 0;
            }
        }

        &.content-section--imageRight {
            .content-section__media__image {
                margin-top: 0;
                max-width: 50%;
            }
            .content-section__content {
                padding: 0 5rem 0 0;
            }
        }

        &.content-section--imageAsBackgroundVideoLeft {

            .content-section__content {
                margin-top: 0;
            }

            h2 {
                font-size: 4rem;
                line-height: 4.375rem;
                margin-bottom: 1.25rem;
            }

            p {
                font-size: 1.25rem;
                line-height: 2.125rem;
            }
        }
    }
}