.testimonials {
  @include e('testimonial') {
    display: none;
    padding: 2rem;

    @include e('content') {
      @include e('quotationmark') {
        font-size: 1.6525rem;
        line-height: 2.75rem;
      }

      @include e('quote') {
        font-size: 1.75rem;
        font-weight: 500;
      }

      @include e('name') {
        font-size: 1rem;
      }

      @include e('company') {
        font-size: .9rem;
        color: #767676;
      }
    }

    @include e('image') {
      border-radius: 100%;
      height: 120px;
      width: 120px;
    }

    @include m('active') {
      display: block
    }

    @include m('left') {
      .testimonials__testimonial__image {
        float: right;
        margin-left: 2.5rem;
      }
      .testimonials__testimonial__content {
        padding-right: 1rem;
      }
    }

    @include m('right') {
      .testimonials__testimonial__image {
        float: left;
        margin-right: 2.5rem;
      }
      .testimonials__testimonial__content {
        padding-left: 1rem;
        text-align: left;
      }
    }

    &:after {
      content: "";
      clear: both;
      display: table;
    }
  }

  @media (min-width: 768px) {
    padding: 3rem 5rem;
    .testimonials__testimonial--active {
      align-items: center;
      display: flex;
    }

    .testimonials__testimonial__content__quotationmark {
      //font-size: 7rem;
      //height: 50px;
      //line-height: 7rem;
    }

    .testimonials__testimonial__content {
      padding-left: 2rem;
      text-align: left;
    }

    .testimonials__testimonial__content__quote {
      font-size: 2.5rem;
      line-height: 3.25rem;
      margin-bottom: 1.25rem;
    }

    .testimonials__testimonial__content__name {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2rem;
    }

    .testimonials__testimonial__content__company {
      font-size: 1.25rem;
      line-height: 1.875rem;
    }

    .testimonials__testimonial__image {
      float: none;
      height: 240px;
      margin-right: 5rem;
      width: 240px;
    }

    .testimonials__testimonial--left {
      .testimonials__testimonial__image {
        margin-left: auto;
        margin-right: unset;
        order: 2;
      }
      .testimonials__testimonial__content {
        order: 1;
        padding-right: 2rem;
        padding-left: 0;
        text-align: left;
      }
    }
  }
}